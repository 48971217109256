document.addEventListener('DOMContentLoaded', () => {

	// Get all "navbar-burger" elements
	const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

	// Check if there are any navbar burgers
	if ($navbarBurgers.length > 0) {

		// Add a click event on each of them
		$navbarBurgers.forEach( el => {
			el.addEventListener('click', () => {

				// Get the target from the "data-target" attribute
				const target = el.dataset.target;
				const $target = document.getElementById(target);

				// Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
				el.classList.toggle('is-active');
				$target.classList.toggle('is-active');
			});
		});
	}

});



// Elements to inject
var elementsToInject = document.querySelectorAll('.injectme');
// Options
var injectorOptions = {
	evalScripts: 'once',
	pngFallback: 'image/fallback'
};
// Trigger the injection
var injector = new SVGInjector(injectorOptions);
injector.inject(
	elementsToInject
);

document.addEventListener('DOMContentLoaded', () => {
	if (document.querySelectorAll(".lity-video") != null) {
		var lity = document.querySelectorAll('.lity-video');
		lity.forEach(function (element, index) {
			var videos = lity[index].getElementsByTagName('figure');
			for (var j = 0; j < videos.length; j++) {
				var ytLink = videos[j].id;
			}
			var org_html = element.innerHTML;
			element.innerHTML = "<a data-lity href='"+ytLink+"'>" + org_html + "</a>";
		});
	}
});
//smooth scrolling
jQuery($ => {
	// The speed of the scroll in milliseconds
	const speed = 1000;
	$('a[href*="#"]')
		.filter((i, a) => a.getAttribute('href').startsWith('#') || a.href.startsWith(`${location.href}#`))
		.unbind('click.smoothScroll')
		.bind('click.smoothScroll', event => {
			const targetId = event.currentTarget.getAttribute('href').split('#')[1];
			const targetElement = document.getElementById(targetId);

			if (targetElement) {
				event.preventDefault();
				//history.pushState(null, null, null);
				var windowWidth = jQuery( window ).width();
				var scrollHelp;
				if(windowWidth < 992) {
					scrollHelp = 115;
				} else {
					scrollHelp = 160;
				}
				$('html, body').animate({ scrollTop: $(targetElement).offset().top - scrollHelp }, speed);
			}
		});
});

//add class on scroll
window.onscroll = function() {
	var currentScrollPos = window.pageYOffset;
	if (currentScrollPos > 35) {
		document.querySelector('header.site-header').classList.add('fade-in');
	} else {
		document.querySelector('header.site-header').classList.remove('fade-in');
	}
}

